document.addEventListener('DOMContentLoaded', () => {
  // idがvideog_playerで始まるiframe
  const iframe = document.querySelector('*|iframe[id^=live_player_]');
  if (iframe == null) return;
  if (!/videog/.test(iframe.src)) return;
  const token = iframe.dataset.token;
  const params = {
    id: null,
    user_id: iframe.dataset.user_id,
    event_id: iframe.dataset.event_id,
    duration: null,
    platform: 'videog',
  };
  // タイマーインターバル (milliseconds)
  const seconds = 1000;                     // 秒 = 1000ミリ秒
  const maxInterval = 180 * seconds;        // 最大180秒
  const incrementalInterval = 30 * seconds; // 増分 30秒
  let interval = 120 * seconds;             // 初期120秒
  // タイマー識別子
  let timerId = null;

  function setTimer() {
    timerId = setInterval(async () => {
      console.info(`timerId: ${timerId}, ${interval / seconds}s have passed.`);
      await post();
    }, interval);
    console.info(`setTimer() timerId: ${timerId}, interval: ${interval / seconds}s`);
  }

  function clearTimer() {
    if (!timerId) return;
    clearInterval(timerId);
    console.info(`clearTimer(${timerId})`);
    timerId = null;
  }

  window.addEventListener('message', receiveMessage);

  function receiveMessage(event) {
    // https://logic-design.zendesk.com/hc/ja/articles/235214068
    const originalData = event.data;
    // ビデオグプレーヤーイベント以外は処理しない
    if (typeof originalData != 'string' || !/videog_player_event/.test(originalData)) return;
    // 通知データの "signature=videog_player_event&name=timeupdate&value=再生秒数" という文字列を分解して配列化
    const eventStrings = originalData.split('&');

    // オブジェクトに変換する
    let eventData = {};
    eventStrings.forEach((string) => {
      const parts = string.split('=', 2);
      const label = parts[0];
      const data = decodeURIComponent(parts[1]);
      eventData[label] = data;
    });

    // 変換したオブジェクトを基に実際の処理を行う
    switch (eventData.name) {
      case 'play':
        (async () => {
          console.info('videog play!');
          if (!params.duration) params.duration = 0;
          await post(); // 再生開始時点でPOST
          // 既に稼働しているタイマーがあったら解除する
          clearTimer();
          setTimer();
        })();
        break;
      case 'pause':
        (async () => {
          console.info('videog pause!');
          // タイマーキャンセル
          clearTimer();
          await post();
        })();
        break;
      case 'timeupdate':
        const intDuration = parseInt(eventData.value);
        if (intDuration) params.duration = intDuration;
        // WebMessagingで処理する関係上、playイベントが正常に拾えない事がある
        // ex. 再生中に再生ボタンを高速ダブルクリック -> pause後のplayが取得出来ない
        // timeupdate(再生時間更新)は再生中と見做し、タイマーが稼働していなければ稼働させる
        if (!timerId) setTimer();
        break;
      default:
    }
  }

  // 視聴ログ記録api呼出し
  async function post() {
    if (params.duration == null) return;
    if (params.duration < 0) return;
    // previewでtokenがセットされていない場合はpostしない
    if (!token) return;
    const url = '/api/viewing_logs/';
    const sanitized = sanitizeParams(params);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const init = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(sanitized),
    };
    const response = await fetch(url, init);
    const json = await response.json();
    const resultId = json.id;
    console.info(`result: ${JSON.stringify(json)}, duration: ${params.duration}s`);
    if (resultId) {
      params.id = resultId;
    } else if (timerId) {
      // 失敗時に稼働中タイマーのinterval増加
      interval = Math.min(interval + incrementalInterval, maxInterval);
      clearTimer();
      setTimer();
    }
  }

  function sanitizeParams(object) {
    // accumulator {} に key: sanitize(value) を セット
    return Object.entries(object).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: sanitize(value) }),
      {}
    );
  }

  // rails側でstringの'null'にならないようにjavascriptのnull(undefined)を空値にする
  function sanitize(value) {
    return value == null ? '' : value;
  }
});
