document.addEventListener('DOMContentLoaded', () => {
  const entryForm = document.getElementById('entry_form');
  if (!entryForm) return;

  const externalId = entryForm.querySelector('input[id*=external_id]');
  const externalOrganization = entryForm.querySelector('input[id*=external_organization]');
  if (!externalId || !externalId.value) return;
  if (!externalOrganization || !externalOrganization.value) return;
  const organization = externalOrganization.value;

  const autoEntryOrganizations = ["doctorbook_authed","carenet","medpeer","eisai"];
  if (autoEntryOrganizations.includes(organization)) entryForm.submit();
});
