document.addEventListener('DOMContentLoaded', () => {
  // スケジュール設定されたdivのList
  const divList = document.querySelectorAll('div[data-scheduled=true]');
  divList.forEach((div) => {
    displayControl(div);
    // タイマーインターバル (milliseconds)
    const interval = 15 * 1000;
    // タイマー識別子
    let timerId;
    // 再帰的タイマー
    timerId = setTimeout(function tick() {
      displayControl(div);
      timerId = setTimeout(tick, interval);
    }, interval);
  });

  function displayControl(element) {
    const dataset = element.dataset;
    // safariがYYYY-MM-DD (included in ECMA-262 standard) に対応しないのでYYYY/MM/DDに置き換える
    const startAt = dataset.startAt ? new Date(dataset.startAt.replace(/-/g, '/')) : null;
    const endAt = dataset.endAt ? new Date(dataset.endAt.replace(/-/g, '/')) : null;
    const now = new Date();
    if ((!startAt || startAt <= now) && (!endAt || now <= endAt)) {
      element.style.display = 'block';
    } else {
      element.style.display = 'none';
    }
  }
});
