require('@rails/ujs').start();

// IE11対策: web specifications
import 'whatwg-fetch';

// images
require.context('../images/front', true);

// css
import '../styles/front/style';

// fontawesome
import '@fortawesome/fontawesome-free/css/all';

// js.erb
import '../scripts/front/auto_entry.js.erb';

// js
import '../scripts/front/vimeo';
import '../scripts/front/videog';
import '../scripts/front/scheduled_display';
